import { GetStaticProps } from 'next'
import Head from 'components/Head'
import Navbar from 'components/Navbar'
import Footer from 'components/Footer'
import company from 'config/company.json'
import { initHeaders } from 'utils/request/headers'
import { loadTranslations } from 'utils/request/i18n'
import {
  NavigationProvider,
  listArticles,
  listAllArticleCategories,
  useLocaleState,
  useTranslation,
  Hero,
  ArticleList,
  BlogNewsletter
} from '@sailogy/shipwright'
import { ArticleListed, ArticleCategoryListed } from '@sailogy/types'
import { K, i18nPath } from 'i18n/blog_list'

interface Props {
  articles: ArticleListed[]
  categories: ArticleCategoryListed[]
  nextPage?: number
}


const BlogHome: React.FC<Props> = ({
  articles, nextPage, categories: ssrCategories
}) => {
  const t = useTranslation<K>()
  const [categories] = useLocaleState<ArticleCategoryListed[]>(
    ssrCategories, async (signal: AbortSignal) => (
      listAllArticleCategories({ signal })
    )
  )

  return (
    <NavigationProvider path="/blog">
      <Head
        title={t('{SITE_NAME} Blog', { SITE_NAME: company.name })}
        description={t('Read %(SITE_NAME)s’s articles: we help you enjoying your sailing holidays.', { SITE_NAME: company.name })}
        image="https://sailogy-pictures-public.imgix.net/img/blog/sh1326622199.jpg"
      />
      <Navbar overlay />
      <Hero
        title={t('Get inspired')}
        excerpt={t('Are you looking for inspiration for your next sailing holiday? Not sure where the wind will blow you next? Packed with insights on trending sailing destinations plus stories from expert sailors and first-timers, our blog will guide your way to your next sail.')}
        // image="https://sailogy-pictures-public.imgix.net/img/blog/sh1326622199.jpg"
        image="https://sailogy-pictures-public.imgix.net/img/blog/sh491965810.jpg"
        objectPosition="top"
      />
      <ArticleList
        list={articles}
        nextPage={nextPage}
        categories={categories}
      />
      <BlogNewsletter magazine="Magister Navis" />
      <Footer />
    </NavigationProvider>
  )
}

// Build SSG pages for each language
export const getStaticProps: GetStaticProps<Props> = async (ctx) => {
  const [
    i18n,
    { results: articles, next },
    categories
  ] = await Promise.all([
    loadTranslations(ctx.locale!, i18nPath),
    listArticles(undefined, initHeaders(ctx)),
    listAllArticleCategories(initHeaders(ctx))
  ])
  return {
    props: {
      ...ctx.params,
      i18n,
      i18nPath,
      articles,
      categories,
      nextPage: next ? 2 : null
    } as Props,
    revalidate: 86400 // 24h
  }
}

export default BlogHome
